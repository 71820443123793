// app/javascript/controllers/location_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["enableButton"]

  connect() {
    if (navigator.geolocation) {
      this.enableButtonTarget.classList.remove('hidden')
    }
  }

  requestLocation() {
    LocationDetection.requestPreciseLocation();
  }
}
