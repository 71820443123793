import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "container"]

  addPart(event) {
    event.preventDefault()
    const content = this.templateTarget.content.cloneNode(true)
    const timestamp = new Date().getTime()
    content.querySelectorAll('[name]').forEach(input => {
      input.name = input.name.replace('NEW_RECORD', timestamp)
    })
    this.containerTarget.appendChild(content)
  }

  removePart(event) {
    event.preventDefault()
    const part = event.target.closest('.design-part')
    const destroyField = part.querySelector('input[name*="_destroy"]')
    if (destroyField) {
      destroyField.value = '1'
      part.style.display = 'none'
    } else {
      part.remove()
    }
  }
}

