import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["staticTitle", "editableTitle", "staticSummary", "editableSummary", "staticContent", "editableContent", "imagePreview", "form", "errorMessages"]

  connect() {
    if (!this.element.dataset.postId) {
      this.enterEditMode()
    }
  }

  toggleEditMode(event) {
    event.preventDefault()
    this.element.classList.toggle("editing")
    this.updateVisibility()
  }

  enterEditMode() {
    this.element.classList.add("editing")
    this.updateVisibility()
  }

  updateVisibility() {
    const isEditing = this.element.classList.contains("editing")
    this.staticTargets.forEach(el => el.classList.toggle("hidden", isEditing))
    this.editableTargets.forEach(el => el.classList.toggle("hidden", !isEditing))
  }

  submitForm(event) {
    event.preventDefault()
    const form = this.formTarget

    // Only include fields that have been changed
    const formData = new FormData(form)
    const changedData = new FormData()
    for (let [key, value] of formData.entries()) {
      if (this.hasFieldChanged(key, value)) {
        changedData.append(key, value)
      }
    }

    Turbo.visit(form.action, {
      method: form.method,
      data: changedData
    })
  }

  hasFieldChanged(key, newValue) {
    const originalValue = this.element.dataset[`original${key.charAt(0).toUpperCase() + key.slice(1)}`]
    return newValue !== originalValue
  }

  handleFormSubmit(event) {
    if (event.detail.success) {
      this.element.classList.remove("editing")
      this.updateVisibility()
      this.clearErrors()
    } else {
      this.showErrors(event.detail.fetchResponse.response)
    }
  }

  showErrors(errorResponse) {
    errorResponse.json().then(data => {
      const errorMessages = data.errors.map(error => `<p>${error}</p>`).join('')
      this.errorMessagesTarget.innerHTML = errorMessages
      this.errorMessagesTarget.classList.remove('hidden')
    })
  }

  clearErrors() {
    this.errorMessagesTarget.innerHTML = ''
    this.errorMessagesTarget.classList.add('hidden')
  }

  get staticTargets() {
    return [this.staticTitleTarget, this.staticSummaryTarget, this.staticContentTarget]
  }

  get editableTargets() {
    return [this.editableTitleTarget, this.editableSummaryTarget, this.editableContentTarget]
  }
}
