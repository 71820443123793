// app/javascript/controllers/registration_slideshow_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide", "form", "nextButton", "prevButton", "submitButton"]
  static values = { currentSlide: Number }

  connect() {
    this.showSlide(this.currentSlideValue)
  }

  next() {
    if (this.currentSlideValue < this.slideTargets.length - 1) {
      this.currentSlideValue++
      this.showSlide(this.currentSlideValue)
    }
  }

  prev() {
    if (this.currentSlideValue > 0) {
      this.currentSlideValue--
      this.showSlide(this.currentSlideValue)
    }
  }

  showSlide(index) {
    this.slideTargets.forEach((slide, i) => {
      slide.classList.toggle('hidden', i !== index)
    })
    this.prevButtonTarget.classList.toggle('hidden', index === 0)
    this.nextButtonTarget.classList.toggle('hidden', index === this.slideTargets.length - 1)
    this.submitButtonTarget.classList.toggle('hidden', index !== this.slideTargets.length - 1)
  }

  submit(event) {
    event.preventDefault()
    // Here you can add any client-side validation before submitting
    this.formTarget.submit()
  }

  togglePasswordVisibility() {
    const type = this.passwordFieldTarget.type === "password" ? "text" : "password"
    this.passwordFieldTarget.type = type
    this.passwordToggleTarget.textContent = type === "password" ? "👁️" : "🙈"
  }
}
