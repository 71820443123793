import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.stripe = Stripe(window.STRIPE_PUBLISHABLE_KEY)
    console.log("Connected to Stripe")
  }

  checkout() {
    const priceId = this.element.dataset.priceId
    fetch("/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ priceId: priceId })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(session => {
        return this.stripe.redirectToCheckout({ sessionId: session.id })
      })
      .catch(error => {
        console.error("Error:", error)
        alert("There was an error processing your payment. Please try again.")
      })
  }
}
