import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "previewContainer"]
  static values = {
    maxSize: { type: Number, default: 5 }, // Max size in MB
    maxFiles: { type: Number, default: 10 } // Max number of files
  }

  connect() {
    this.fileList = new DataTransfer()
    // If there are existing previews (from previous step), initialize fileList
    this.loadExistingPreviews()
  }

  loadExistingPreviews() {
    const existingPreviews = this.previewContainerTarget.querySelectorAll('[data-existing-image]')
    existingPreviews.forEach(preview => {
      const signedId = preview.dataset.signedId
      if (signedId) {
        // Store reference to existing images
        preview.querySelector('.remove-button')?.addEventListener('click', (e) => {
          e.preventDefault()
          this.removeExistingPreview(preview, signedId)
        })
      }
    })
  }

  handleFiles(event) {
    const files = event.target.files

    if (!this.validateFiles(files)) {
      // Reset input
      this.inputTarget.value = ''
      return
    }

    Array.from(files).forEach(file => {
      if (file.type.startsWith('image/')) {
        this.addPreview(file)
      }
    })

    // Update the actual file input with our managed FileList
    this.inputTarget.files = this.fileList.files
  }

  validateFiles(files) {
    const totalFiles = this.fileList.files.length + files.length
    if (totalFiles > this.maxFilesValue) {
      alert(`You can only OCupload up to ${this.maxFilesValue} images`)
      return false
    }

    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        alert(`${file.name} is not an image file`)
        return false
      }

      const sizeInMB = file.size / (1024 * 1024)
      if (sizeInMB > this.maxSizeValue) {
        alert(`${file.name} is too large. Maximum file size is ${this.maxSizeValue}MB`)
        return false
      }
    }

    return true
  }

  addPreview(file) {
    const reader = new FileReader()
    const div = document.createElement('div')
    div.className = 'relative group'

    reader.onload = (e) => {
      div.innerHTML = `
        <div class="relative" data-new-image>
          <img src="${e.target.result}" 
               class="w-full h-48 object-cover rounded-lg" 
               alt="${file.name}">
          <div class="absolute top-2 right-2">
            <button type="button" 
                    class="remove-button bg-red-500 text-white p-1 rounded-full hover:bg-red-600 transition-colors"
                    data-action="click->image-preview#removePreview">
              ×
            </button>
          </div>
          <div class="absolute bottom-2 left-2 right-2">
            <div class="text-sm text-white bg-black bg-opacity-50 rounded p-1">
              ${file.name} (${(file.size / (1024 * 1024)).toFixed(1)} MB)
            </div>
          </div>
        </div>
      `
      this.previewContainerTarget.appendChild(div)

      // Add to managed FileList
      this.fileList.items.add(file)
    }

    reader.readAsDataURL(file)
  }

  removePreview(event) {
    event.preventDefault()
    const previewElement = event.target.closest('[data-new-image]')?.parentElement
    if (!previewElement) return

    // Remove from managed FileList
    const imageIndex = Array.from(this.previewContainerTarget.children).indexOf(previewElement)
    const newFileList = new DataTransfer()

    Array.from(this.fileList.files)
      .filter((_, index) => index !== imageIndex)
      .forEach(file => newFileList.items.add(file))

    this.fileList = newFileList
    this.inputTarget.files = this.fileList.files

    // Remove preview
    previewElement.remove()
  }

  removeExistingPreview(previewElement, signedId) {
    // Send AJAX request to remove the image
    fetch(`/designs/${designId}/remove_image/${signedId}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
    }).then(response => {
      if (response.ok) {
        // Update hidden field
        const currentIds = JSON.parse(this.signedIdsTarget.value || '[]')
        const newIds = currentIds.filter(id => id !== signedId)
        this.signedIdsTarget.value = JSON.stringify(newIds)

        previewElement.remove()
      } else {
        alert('Failed to remove image. Please try again.')
      }
    }).catch(error => {
      console.error('Error removing image:', error)
      alert('Failed to remove image. Please try again.')
    })
  }
}
